import React, { useState, useEffect } from "react";
import ColorSlider from "./ColorSlider.js";
import BountyChart from "./Chart.js";
import "./Calculator.css";
import {
  calculateBountiesForTier,
  calculateStats,
} from "../utils/calculations.js";
import {
  sliderMarksMaturity,
  sliderMarksRisk,
  getTierColor,
  getStatColor,
  getSkillLevelValueForBounty,
} from "../utils/values.js";
import {
  formatNumber,
  convertValuesToCurrency,
} from "../utils/transformers.js";

import Radio from "@mui/material/Radio";

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@mui/material/Select";
import ArrowTooltip from "./ArrowTooltip.js";
import HubspotForm from "react-hubspot-form";

const TIERS_MAX = 5;

function Calculator({ industries, currencies }) {
  // Define initial state
  useEffect(() => {
    const handleError = () => {
      // Reset state and clear URL parameters
      //No longer doing this due to CookieYes throwing an error
      //resetStateAndClearURL();
      //window.location.reload();
    };

    window.addEventListener("error", handleError);
    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  const [state, setState] = useState({
    options: {
      includeExceptional: true,
      expertMode: false,
      captureFormShown: true,
    },
    industries: industries,
    currencies: currencies,
    tiers: [
      {
        name: "Tier 1",
        maturity: 50,
        risk: 50,
        curve: 1.5,
        isLocked: true,
        bounties: [0, 0, 0, 0, 0],
        color: getTierColor(0),
      },
    ],
  });

  const calculateBountiesForTiers = (convertedIndustryMedians) => {
    let options = state.options;
    state.tiers.forEach((tier) => {
      tier.bounties = calculateBountiesForTier(
        tier,
        convertedIndustryMedians,
        options
      );
    });
  };

  // Function to update state and URL parameters
  const updateStateAndURL = (newState) => {
    setState(newState);

    // Update URL with current state parameters
    const urlParams = new URLSearchParams();
    const industry =
      newState.industries.find((industry) => industry.selected)?.name || "";
    const currency =
      newState.currencies.find((currency) => currency.selected)?.code || "";
    urlParams.set("industry", industry);
    urlParams.set("curve", newState.options.curve);
    urlParams.set("confidence", newState.options.confidence);
    urlParams.set("currency", currency);
    urlParams.set("expertMode", newState.options.expertMode);
    urlParams.set("captureFormShown", newState.options.captureFormShown);
    urlParams.set("tiers", btoa(JSON.stringify(newState.tiers)));

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  // Function to parse URL parameters and initialize state
  const initializeStateFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const _industry = urlParams.get("industry");
    const _currency = urlParams.get("currency");
    const tiersString = urlParams.get("tiers");
    const curve = urlParams.get("curve");

    var confidence = urlParams.get("confidence");
    if (confidence) {
      confidence = parseFloat(confidence);
    }
    const expertMode = urlParams.get("expertMode");
    let captureFormShown = true;
    if (
      urlParams.get("captureFormShown") &&
      urlParams.get("captureFormShown") === "false"
    ) {
      captureFormShown = false;
    }
    let tiers = [];
    try {
      tiers = tiersString ? JSON.parse(atob(tiersString)) : [];
    } catch (ex) {
      console.log(ex);
    }

    let updatedIndustries = state.industries;
    let updatedCurrencies = state.currencies;
    let updatedTiers = state.tiers;

    if (_industry) {
      updatedIndustries = state.industries.map((industry) => ({
        ...industry,
        selected: industry.name === _industry,
      }));
    }

    if (_currency) {
      updatedCurrencies = state.currencies.map((currency) => ({
        ...currency,
        selected: currency.code === _currency,
      }));
    }

    if (tiers.length > 0) {
      updatedTiers = tiers;
    }

    let options = state.options;
    if (curve) {
      options.curve = curve;
    }
    if (confidence) {
      options.confidence = confidence;
    }
    options.captureFormShown = captureFormShown;

    if (expertMode) {
      options.expertMode = expertMode === "true";
    }

    setState({
      ...state,
      options: options,
      industries: updatedIndustries,
      currencies: updatedCurrencies,
      tiers: updatedTiers,
    });
  };

  // useEffect to initialize state from URL parameters on page load
  useEffect(() => {
    initializeStateFromURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [uiStateChange, setUiStateChange] = useState(false);

  useEffect(() => {
    if (uiStateChange) {
      updateStateAndURL(state);
      setUiStateChange(false); // Reset to false after updating URL
    }
  }, [state, uiStateChange]);

  // Update state with UI-triggered change
  const updateStateWithUiChange = (updatedState) => {
    setState(updatedState);
    setUiStateChange(true); // Set uiStateChange to true to indicate UI-triggered change
  };

  const getSelectedIndustry = () => {
    let selectedIndustry =
      state.industries.find((industry) => industry.selected) || {};

    // Copy the arrays instead of assigning them directly
    selectedIndustry.averagesWithoutExceptional =
      selectedIndustry.averages.slice();
    let industryAvgExceptional =
      selectedIndustry.averagesWithoutExceptional.pop();
    selectedIndustry.averagesWithoutExceptional[
      selectedIndustry.averagesWithoutExceptional.length - 1
    ] =
      (selectedIndustry.averagesWithoutExceptional[
        selectedIndustry.averagesWithoutExceptional.length - 1
      ] +
        industryAvgExceptional) /
      2;

    selectedIndustry.mediansWithoutExceptional =
      selectedIndustry.medians.slice();
    let industryMedianExceptional =
      selectedIndustry.mediansWithoutExceptional.pop();
    selectedIndustry.mediansWithoutExceptional[
      selectedIndustry.mediansWithoutExceptional.length - 1
    ] =
      (selectedIndustry.mediansWithoutExceptional[
        selectedIndustry.mediansWithoutExceptional.length - 1
      ] +
        industryMedianExceptional) /
      2;

    return selectedIndustry;
  };

  const getSelectedCurrency = () => {
    return state.currencies.find((currency) => currency.selected) || {};
  };

  // Event handlers
  const handleFormSubmit = (event) => {
    let newState = state;
    newState.options.captureFormShown = false;
    updateStateWithUiChange(newState);
  };

  const handleIndustryChange = (event) => {
    const selectedIndustryName = event.target.value;
    const updatedIndustries = state.industries.map((industry) => ({
      ...industry,
      selected: industry.name === selectedIndustryName,
    }));
    updateStateWithUiChange({ ...state, industries: updatedIndustries });
  };

  const handleCurrencyChange = (event) => {
    const selectedCurrencyCode = event.target.value;
    const updatedCurrencies = state.currencies.map((currency) => ({
      ...currency,
      selected: currency.code === selectedCurrencyCode,
    }));
    updateStateWithUiChange({ ...state, currencies: updatedCurrencies });
  };

  const handleBountyChange = (tierIndex, severityIndex, event) => {
    const newTiers = [...state.tiers];
    var value = parseFloat(event.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    newTiers[tierIndex].bounties[severityIndex] = value;
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleTierNameChange = (index, event) => {
    const newTiers = [...state.tiers];
    newTiers[index].name = event.target.value;
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleTierDelete = (index) => {
    if (state.tiers.length === 1) {
      // If there's only one tier left, don't delete it
      return;
    }
    const newTiers = [...state.tiers];
    newTiers.splice(index, 1);
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleTierLock = (event, index) => {
    event.preventDefault(); // Prevent form submission from reloading the page
    const newTiers = [...state.tiers];
    newTiers[index].isLocked = !newTiers[index].isLocked;
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleAddTier = (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page
    let newTier = {
      name: `Tier ${state.tiers.length + 1}`,
      maturity: 50,
      risk: 50,
      curve: 1.5,
      bounties: [],
      isLocked: true,
      color: getTierColor(state.tiers.length),
    };
    updateStateWithUiChange({ ...state, tiers: [...state.tiers, newTier] });
  };

  const handleRiskChange = (index, value) => {
    const newTiers = [...state.tiers];
    newTiers[index].risk = parseInt(value);
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleCurveChange = (index, e) => {
    const newTiers = [...state.tiers];
    newTiers[index].curve = parseFloat(e.target.value);
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleMaturityChange = (index, value) => {
    const newTiers = [...state.tiers];
    newTiers[index].maturity = parseInt(value);
    updateStateWithUiChange({ ...state, tiers: newTiers });
  };

  const handleConfidenceLevelChange = (event) => {
    const confidence = parseInt(event.target.value); // Get the checked value of the checkbox
    let isConfidenceSet = true;
    updateStateWithUiChange({
      ...state,
      options: { ...state.options, confidence },
      isConfidenceSet,
    }); // Update the includeExceptional option in the state
  };

  const handleGeneralCurveLevelChange = (event) => {
    const curve = event.target.value; // Get the checked value of the checkbox
    let isCurveLevelSet = true;
    updateStateWithUiChange({
      ...state,
      options: { ...state.options, curve },
      isCurveLevelSet,
    }); // Update the includeExceptional option in the state
  };

  const handleIncludeExceptionalToggle = (event) => {
    const includeExceptional = event.target.checked; // Get the checked value of the checkbox
    updateStateWithUiChange({
      ...state,
      options: { ...state.options, includeExceptional },
    }); // Update the includeExceptional option in the state
  };

  const handleExpertModeToggle = (event) => {
    const expertMode = event.target.checked; // Get the checked value of the checkbox
    updateStateWithUiChange({
      ...state,
      options: { ...state.options, expertMode },
    }); // Update the includeExceptional option in the state
  };

  let selectedIndustry = getSelectedIndustry();
  let selectedCurrency = getSelectedCurrency();
  let convertedIndustryAverages = state.options.includeExceptional
    ? convertValuesToCurrency(selectedIndustry.averages, selectedCurrency)
    : convertValuesToCurrency(
        selectedIndustry.averagesWithoutExceptional,
        selectedCurrency
      );
  let convertedIndustryMedians = state.options.includeExceptional
    ? convertValuesToCurrency(selectedIndustry.medians, selectedCurrency)
    : convertValuesToCurrency(
        selectedIndustry.mediansWithoutExceptional,
        selectedCurrency
      );

  calculateBountiesForTiers(convertedIndustryMedians);
  let stats = calculateStats(
    state.tiers,
    convertedIndustryMedians,
    convertedIndustryAverages
  );

  return (
    <section className="container-wizard">
      <div className="wrapped">
        <script
          charset="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/embed/v2.js"
        ></script>
        <form className="wizard wizard-company">
          <div className="title-with-input">
            <h2>
              1.{" "}
              {state.options.expertMode ? (
                <span>General information</span>
              ) : (
                <span>Answer these questions</span>
              )}
            </h2>
            <div className="toggle-container-group">
              <div className="input-container input-toggle-container">
                <label
                  htmlFor="expert"
                  className={
                    state.options.expertMode
                      ? "mode-label"
                      : "mode-label mode-label-current"
                  }
                >
                  Basic
                </label>
                <label className="expert-toggle">
                  <input
                    type="checkbox"
                    id="expert"
                    onChange={handleExpertModeToggle}
                    checked={state.options.expertMode}
                  />
                  <span className="expert-slider round"></span>
                </label>
                <label
                  htmlFor="expert"
                  className={
                    state.options.expertMode
                      ? "mode-label-current"
                      : "mode-label mode-label"
                  }
                >
                  Advanced
                </label>
              </div>
            </div>
          </div>
          <div className="input-container-group">
            <div className="input-container">
              <FormControl fullWidth required>
                <FormLabel id="input-industry-simple-select-label">
                  What kind of industry are you in?
                </FormLabel>
                <Select
                  labelId="input-industry-simple-select-label"
                  id="input-industry-select"
                  value={getSelectedIndustry().name || ""}
                  onChange={(newValue) => handleIndustryChange(newValue)}
                >
                  <MenuItem disabled value="">
                    Select industry
                  </MenuItem>
                  {state.industries.map((industry) => (
                    <MenuItem value={industry.name}>{industry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="input-container">
              <FormControl fullWidth required>
                <FormLabel id="input-industry-simple-select-label">
                  What is your currency?
                </FormLabel>
                <Select
                  labelId="input-currency-simple-select-label"
                  id="input-currency-select"
                  value={getSelectedCurrency().code || "EUR"}
                  onChange={(newValue) => handleCurrencyChange(newValue)}
                >
                  <MenuItem disabled value="">
                    Select currency
                  </MenuItem>
                  {state.currencies.map((currency) => (
                    <MenuItem value={currency.code}>
                      {currency.code} &nbsp;
                      {currency.symbol ? (
                        <span>({currency.symbol})</span>
                      ) : null}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {state.options.expertMode ? null : (
            <div className="input-container-group">
              <div className="input-container">
                <FormControl onChange={handleConfidenceLevelChange} required>
                  <FormLabel id="input-confidence-radio-buttons-group-label">
                    How confident are you in your security posture?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="input-confidence-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={state.options.confidence}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Not confident at all"
                      checked={state.options.confidence === 0}
                    />
                    <FormControlLabel
                      value={25}
                      control={<Radio />}
                      label="Slightly confident"
                      checked={state.options.confidence === 25}
                    />
                    <FormControlLabel
                      value={50}
                      control={<Radio />}
                      label="Somewhat confident"
                      checked={state.options.confidence === 50}
                    />
                    <FormControlLabel
                      value={75}
                      control={<Radio />}
                      label="Confident"
                      checked={state.options.confidence === 75}
                    />
                    <FormControlLabel
                      value={100}
                      control={<Radio />}
                      label="Very confident"
                      checked={state.options.confidence === 100}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="input-container">
                <FormControl onChange={handleGeneralCurveLevelChange} required>
                  <FormLabel id="input-budgetoptimalisation-radio-buttons-group-label">
                    How would you like to optimize budget?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="input-budgetoptimalisation-radio-buttons-group-label"
                    name="radio-buttons-group"
                    required
                    value={state.options.curve}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Focus on coverage: identify as many vulnerabilities as possible, regardless of the severity"
                      checked={state.options.curve === "1"}
                    />
                    <FormControlLabel
                      value={1.5}
                      control={<Radio />}
                      label="Focus on balance: equally prioritize coverage while considering their severity and impact"
                      checked={state.options.curve === "1.5"}
                    />
                    <FormControlLabel
                      value={2.5}
                      control={<Radio />}
                      label="Focus on impact: reserve most budget for highly impactful findings"
                      checked={state.options.curve === "2.5"}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          )}
        </form>
        {state.options.expertMode ? (
          <form className="wizard wizard-assets">
            <h2>2. Describe your assets</h2>
            <div className="table asset-table">
              <div className="table-header">
                <div className="table-row">
                  <span className="table-cell">Tier name</span>
                  <span className="table-cell">
                    Maturity
                    <ArrowTooltip>
                      <h4 class="tooltip-title">DoD's CMMCv1 Model</h4>
                      <ul>
                        <li>
                          <b>Basic: </b> ad-hoc security implementations
                        </li>
                        <li>
                          <b>Intermediate: </b> documented security practices
                        </li>
                        <li>
                          <b>Good: </b> security protocols are managed and
                          documented
                        </li>
                        <li>
                          <b>Proactive: </b> all of the above is actively
                          reviewed
                        </li>
                        <li>
                          <b>Advanced: </b> everything is documented, reviewed
                          and covered
                        </li>
                      </ul>
                    </ArrowTooltip>
                  </span>
                  <span className="table-cell">
                    Risk
                    <ArrowTooltip>
                      <h4 class="tooltip-title">Risk matrix</h4>
                      <p class="tooltip-text">
                        <b>Impact</b> vs <b>Likelihood</b>
                      </p>
                      <table className="table-impact-likelihood">
                        <thead>
                          <tr>
                            <td></td>
                            <td>Neglible</td>
                            <td>Minor</td>
                            <td>Moderate</td>
                            <td>Significant</td>
                            <td>Severe</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Very likely</td>
                            <td>Low</td>
                            <td>Medium</td>
                            <td>High</td>
                            <td>Very high</td>
                            <td>Very high</td>
                          </tr>
                          <tr>
                            <td>Likely</td>
                            <td>Very low</td>
                            <td>Low</td>
                            <td>Medium</td>
                            <td>High</td>
                            <td>Very high</td>
                          </tr>
                          <tr>
                            <td>Possible</td>
                            <td>Very low</td>
                            <td>Low</td>
                            <td>Medium</td>
                            <td>High</td>
                            <td>High</td>
                          </tr>
                          <tr>
                            <td>Unlikely</td>
                            <td>Very low</td>
                            <td>Low</td>
                            <td>Low</td>
                            <td>Medium</td>
                            <td>High</td>
                          </tr>
                          <tr>
                            <td>Very unlikely</td>
                            <td>Very low</td>
                            <td>Very low</td>
                            <td>Low</td>
                            <td>Medium</td>
                            <td>Medium</td>
                          </tr>
                        </tbody>
                      </table>
                    </ArrowTooltip>
                  </span>
                  <span className="table-cell">
                    Curve
                    <ArrowTooltip>
                      <h4 class="tooltip-title">Incentive curve</h4>
                      <ul>
                        <li>
                          <b>Moving:</b> Follow the median values of the
                          industry
                        </li>
                        <li>
                          <b>Linear:</b> Linear incentive as severity increased
                        </li>
                        <li>
                          <b>Bended:</b> Higher severities slightly more
                          incentivised
                        </li>
                        <li>
                          <b>Inclining:</b> Higher severities more incentivised
                        </li>
                        <li>
                          <b>Steep:</b> Higher severities strongly more
                          incentivised
                        </li>
                      </ul>
                    </ArrowTooltip>
                  </span>
                  <span className="table-cell cell-actions">Actions</span>
                </div>
              </div>
              <div className="table-body">
                {state.tiers.map((tier, index) => (
                  <div className="table-row" key={index}>
                    <div className="table-cell">
                      <TextField
                        type="text"
                        value={tier.name}
                        onChange={(e) => handleTierNameChange(index, e)}
                        sx={{
                          "& .MuiInputBase-input": {
                            paddingRight: 4,
                            paddingLeft: 2,
                            paddingTop: 1,
                            paddingBottom: 1,
                          },
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="table-cell">
                      <ColorSlider
                        value={tier.maturity}
                        marks={sliderMarksMaturity}
                        onChange={(newValue) =>
                          handleMaturityChange(index, newValue)
                        }
                      />
                    </div>
                    <div className="table-cell">
                      <ColorSlider
                        value={tier.risk}
                        marks={sliderMarksRisk}
                        onChange={(newValue) =>
                          handleRiskChange(index, newValue)
                        }
                      />
                    </div>
                    <div className="table-cell">
                      <FormControl
                        sx={{
                          "& .MuiSelect-select": {
                            paddingRight: 4,
                            paddingLeft: 2,
                            paddingTop: 1,
                            paddingBottom: 1,
                          },
                        }}
                        fullWidth
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tier.curve}
                          onChange={(newValue) =>
                            handleCurveChange(index, newValue)
                          }
                        >
                          <MenuItem value={0}>Moving</MenuItem>
                          <MenuItem value={1}>Linear</MenuItem>
                          <MenuItem value={1.5}>Bended</MenuItem>
                          <MenuItem value={2}>Inclining</MenuItem>
                          <MenuItem value={2.5}>Steep</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="table-cell cell-actions">
                      <button
                        className="btn-tier-delete"
                        onClick={(e) => {
                          e.preventDefault();
                          handleTierDelete(index);
                        }}
                        disabled={state.tiers.length === 1}
                      >
                        <FontAwesomeIcon icon="fas fa-trash" />
                      </button>
                      {index === state.tiers.length - 1 &&
                      state.tiers.length < TIERS_MAX ? (
                        <button
                          className="btn-tier-add"
                          onClick={handleAddTier}
                        >
                          <FontAwesomeIcon icon="fas fa-circle-plus" />
                          &nbsp;Add tier
                        </button>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        ) : null}

        {state.options.expertMode ||
        (state.options.curve !== undefined &&
          state.options.confidence !== undefined) ? (
          <div className="wizard-results">
            <h2>
              {state.options.expertMode ? <span>3</span> : <span>2</span>}. View
              results
            </h2>
            <div className="results-container">
              {
                <div
                  class="results-form"
                  style={{
                    display: state.options.captureFormShown ? "block" : "none",
                  }}
                >
                  <h3>Tell us a little bit more about your situation</h3>
                  <p>
                    In order to provide you with the most accurate estimates, we
                    need to learn a little bit more about you.
                  </p>
                  <HubspotForm
                    portalId="7473434"
                    formId="063257ee-598f-49f3-85e7-0db80208af27"
                    onSubmit={handleFormSubmit}
                    onReady={(form) => {}}
                    loading={<div className="results-form">Loading...</div>}
                  />
                </div>
              }
              <div className={"results-stats-container"}>
                <h3>Industry benchmark</h3>
                <div className="results-stats">
                  <div className="stat-container">
                    <output
                      className={
                        state.options.captureFormShown ? "blurred" : ""
                      }
                      style={{ color: getStatColor(stats.industryAvgDiffPct) }}
                    >
                      {Math.abs(stats.industryAvgDiffPct)}%
                    </output>
                    <label>
                      {stats.industryAvgDiffPct < 0 ? (
                        <span>Below</span>
                      ) : (
                        <span>Above</span>
                      )}
                      &nbsp;industry average
                    </label>
                  </div>
                  <div className="stat-container">
                    <output
                      className={
                        state.options.captureFormShown ? "blurred" : ""
                      }
                      style={{
                        color: getStatColor(stats.industryMedianDiffPct),
                      }}
                    >
                      {Math.abs(stats.industryMedianDiffPct)}%
                    </output>
                    <label>
                      {stats.industryMedianDiffPct < 0 ? (
                        <span>Below</span>
                      ) : (
                        <span>Above</span>
                      )}
                      &nbsp;industry median
                    </label>
                  </div>
                  <div className="stat-container">
                    <output
                      className={
                        state.options.captureFormShown ? "blurred" : ""
                      }
                      style={{
                        color: getSkillLevelValueForBounty(
                          stats.maxBountyValues[
                            stats.maxBountyValues.length - 1
                          ]
                        ).color,
                      }}
                    >
                      {
                        getSkillLevelValueForBounty(
                          stats.maxBountyValues[
                            stats.maxBountyValues.length - 1
                          ]
                        ).title
                      }
                    </output>
                    <label>Estimated level of attracted hackers</label>
                  </div>
                </div>
                <div
                  className={
                    "result-graph stat-container " +
                    (state.options.captureFormShown ? "blurred" : "")
                  }
                >
                  <BountyChart
                    industryMedians={convertedIndustryMedians}
                    industryAverages={convertedIndustryAverages}
                    tiers={state.tiers}
                    includeExceptional={state.options.includeExceptional}
                  />
                </div>
              </div>
            </div>
            {state.options.expertMode ||
            (state.options.curve && state.options.confidence) ? (
              <form className="wizard wizard-bounties">
                <div className="title-with-input">
                  <h3>Proposed bounty table</h3>
                  <span className="input-container input-checkbox-container">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.options.includeExceptional}
                          onChange={handleIncludeExceptionalToggle}
                        />
                      }
                      label="Include exceptional category"
                      sx={{ color: "#4D59A8" }}
                    />
                  </span>
                </div>
                <div className="table bounties-table">
                  <div className="table-header">
                    <div className="table-row">
                      <span className="table-cell">CVSSv3 score →</span>
                      <span className="table-cell">
                        <span className="label-severity severity-low">
                          Low (0.1 - 3.9)
                        </span>
                      </span>
                      <span className="table-cell">
                        <span className="label-severity severity-medium">
                          Medium (4.0 - 6.9)
                        </span>
                      </span>
                      <span className="table-cell">
                        <span className="label-severity severity-high">
                          High (7.0 - 8.9)
                        </span>
                      </span>
                      <span className="table-cell">
                        {state.options.includeExceptional ? (
                          <span className="label-severity severity-critical">
                            Critical (9.0 - 9.4)
                          </span>
                        ) : (
                          <span className="label-severity severity-critical">
                            Critical (9.0 - 10.0)
                          </span>
                        )}
                      </span>

                      {state.options.includeExceptional ? (
                        <span className="table-cell">
                          <span className="label-severity severity-exceptional">
                            Exceptional (9.5 - 10.0)
                          </span>
                        </span>
                      ) : null}
                      <span className="table-cell cell-actions"></span>
                    </div>
                  </div>
                  <div className="table-body">
                    {state.tiers.map((tier, tierIndex) => (
                      <div className="table-row" key={tierIndex}>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="input-tier-name"
                            value={tier.name}
                            onChange={(e) => handleTierNameChange(tierIndex, e)}
                            style={{ borderColor: tier.color }}
                          />
                        </div>
                        {tier.bounties.map((bounty, severityIndex) =>
                          !(
                            !state.options.includeExceptional &&
                            severityIndex === 4
                          ) ? (
                            <div className="table-cell " key={severityIndex}>
                              <div className="input-container unit-input">
                                <span className="input-unit">
                                  {selectedCurrency.symbol
                                    ? selectedCurrency.symbol
                                    : selectedCurrency.code}
                                </span>
                                <span
                                  className={
                                    state.options.captureFormShown
                                      ? "blurred"
                                      : ""
                                  }
                                >
                                  {tier.isLocked ? (
                                    <input
                                      type="text"
                                      value={formatNumber(bounty)}
                                      disabled
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      value={bounty}
                                      onChange={(e) =>
                                        handleBountyChange(
                                          tierIndex,
                                          severityIndex,
                                          e
                                        )
                                      }
                                    />
                                  )}
                                </span>
                                <span className="input-severity">
                                  {
                                    [
                                      "Low",
                                      "Medium",
                                      "High",
                                      "Critical",
                                      "Exceptional",
                                    ][severityIndex]
                                  }
                                </span>
                              </div>
                            </div>
                          ) : null
                        )}
                        <div className="table-cell cell-actions">
                          <button
                            className="btn-round btn-outline btn-lock"
                            onClick={(e) => handleTierLock(e, tierIndex)}
                          >
                            {tier.isLocked ? (
                              <FontAwesomeIcon icon="fas fa-lock" />
                            ) : (
                              <FontAwesomeIcon icon="fas fa-unlock" />
                            )}
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="table-row-group row-group-industries">
                      <div className="table-row" key="industry-averages">
                        <div className="table-cell">
                          <input
                            type="text"
                            className="input-tier-name"
                            value="Industry average"
                            disabled
                          />
                        </div>
                        {convertedIndustryAverages.map(
                          (convertedIndustryAverage, severityIndex) => (
                            <div className="table-cell" key={severityIndex}>
                              <div className="input-container unit-input">
                                <span className="input-unit">
                                  {selectedCurrency.symbol
                                    ? selectedCurrency.symbol
                                    : selectedCurrency.code}
                                </span>
                                <span
                                  className={
                                    state.options.captureFormShown
                                      ? "blurred"
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    value={formatNumber(
                                      convertedIndustryAverage
                                    )}
                                    disabled
                                  />
                                </span>
                                <span className="input-severity">
                                  {
                                    [
                                      "Low",
                                      "Medium",
                                      "High",
                                      "Critical",
                                      "Exceptional",
                                    ][severityIndex]
                                  }
                                </span>
                              </div>
                            </div>
                          )
                        )}
                        <div className="table-cell cell-actions"></div>
                      </div>
                      <div className="table-row" key="industry-medians">
                        <div className="table-cell">
                          <input
                            type="text"
                            className="input-tier-name"
                            value="Industry medians"
                            disabled
                          />
                        </div>
                        {convertedIndustryMedians.map(
                          (convertedIndustryMedian, severityIndex) => (
                            <div className="table-cell" key={severityIndex}>
                              <div className="input-container unit-input">
                                <span className="input-unit">
                                  {selectedCurrency.symbol
                                    ? selectedCurrency.symbol
                                    : selectedCurrency.code}
                                </span>
                                <span
                                  className={
                                    state.options.captureFormShown
                                      ? "blurred"
                                      : ""
                                  }
                                >
                                  <input
                                    type="text"
                                    value={formatNumber(
                                      convertedIndustryMedian
                                    )}
                                    disabled
                                  />
                                </span>
                                <span className="input-severity">
                                  {
                                    [
                                      "Low",
                                      "Medium",
                                      "High",
                                      "Critical",
                                      "Exceptional",
                                    ][severityIndex]
                                  }
                                </span>
                              </div>
                            </div>
                          )
                        )}
                        <div className="table-cell cell-actions"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default Calculator;
