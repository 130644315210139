import "./TextBlock.css";
import ImgHunter from "../images/hunter.png";

function TextBlock() {
  return (
    <section className="container-intro">
      <div className="container-intro-wrapper">
        <div className="wrapped">
          <div className="container-intro-text">
            <h1 className="title-intro">Bug Bounty Calculator</h1>
            <p className="text-intro">
              Are you interested in rewarding a security researcher for
              reporting a vulnerability but uncertain about the appropriate
              amount? We've analyzed over 640 bug bounty tables across various
              industries to help you make an informed decision and benchmark
              against your industry peers! <br />
              To learn more about how the Bug Bounty Calculator works, take{" "}
              <a
                href="https://go.intigriti.com/bountycalculator-tour"
                target="_blank"
                rel="noreferrer"
              >
                a guided tour
              </a>{" "}
              or read{" "}
              <a
                href="https://go.intigriti.com/bountycalculator-blog"
                target="_blank"
                rel="noreferrer"
              >
                our recent blog post
              </a>
              . <br />
            </p>
          </div>
          <div className="container-intro-image">
            <img
              className="image-intro"
              alt="Bug Bounty Calculator"
              src={ImgHunter}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TextBlock;
