import React, { useState } from "react";
import Slider from "@mui/material/Slider";

function ColorSlider({ value, onChange, marks }) {
  const [sliderValue, setSliderValue] = useState(value);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    onChange(newValue);
  };

  let color;
  let backgroundColor;
  if (sliderValue < 25) {
    color = "#02a87c";
    backgroundColor = "#ebf6f2";
  } else if (sliderValue < 50) {
    color = "#e0ac00";
    backgroundColor = "#fcf7ea";
  } else if (sliderValue < 75) {
    color = "#f69833";
    backgroundColor = "#fff5ec";
  } else if (sliderValue < 100) {
    color = "#f03257";
    backgroundColor = "#ffebee";
  } else if (sliderValue === 100) {
    color = "#ce1c51";
    backgroundColor = "#fbe9ed";
  }

  let theme = {
    "& .MuiSlider-thumb": {
      color: color,
    },
    "& .Mui-focusVisible": {
      color: color,
      boxShadow: `0 0 0 10px ${color}1a !important`,
    },
    "& .MuiSlider-thumb:hover": {
      color: color,
      boxShadow: `0 0 0 10px ${color}1a !important`,
    },
    "& .MuiSlider-thumb:active": {
      color: color,
      boxShadow: `0 0 0 10px ${color}1a !important`,
    },
    "& .MuiSlider-thumb.Mui-active": {
      color: color,
      boxShadow: `0 0 0 10px ${color}1a !important`,
    },
    "& .MuiSlider-track": {
      color: color,
    },
    "& .MuiSlider-rail": {
      color: color,
    },
    "& .MuiSlider-active": {
      color: color,
    },
    "& .MuiSlider-valueLabel": {
      color: color,
      backgroundColor: backgroundColor,
    },
  };

  return (
    <Slider
      defaultValue={sliderValue}
      value={sliderValue}
      step={25}
      min={0}
      max={100}
      valueLabelFormat={(value) => <div>{marks[value / 25].label}</div>}
      aria-label="Default"
      valueLabelDisplay="auto"
      onChange={handleSliderChange}
      sx={theme}
    />
  );
}

export default ColorSlider;
