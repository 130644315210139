const calculateBountiesForTier = (tier, convertedIndustryMedians, options) => {
  if (!tier.isLocked) {
    //Fix edgecase where someone unlocks when exceptionals are not shown
    if (options.includeExceptional && tier.bounties.length === 4) {
      tier.bounties.push(tier.bounties[tier.bounties.length - 1]);
    } else if (!options.includeExceptional && tier.bounties.length === 5) {
      tier.bounties.pop();
    }
    return tier.bounties;
  }
  const bounties = [];

  for (let severityIndex = 0; severityIndex < 5; severityIndex++) {
    const severity = severityIndex + 1; // Adjust severity index to start from 1
    let multiplier = options.expertMode
      ? (Math.max(tier.maturity, 10) / 50) * (Math.max(tier.risk, 10) / 50) +
        0.1
      : Math.max(options.confidence, 10) / 50 + 0.1;
    if (options.expertMode && tier.maturity === 0 && tier.risk === 0) {
      multiplier = 0;
    }
    let curve = options.expertMode ? tier.curve : options.curve;
    if (curve === 0) {
      const base = convertedIndustryMedians[severityIndex];
      const bounty = Math.ceil((base * multiplier) / 25) * 25;
      bounties.push(bounty);
    } else {
      const cvss = (severity / 4) * 10;
      const bMaxBase =
        convertedIndustryMedians[convertedIndustryMedians.length - 1];
      const bMinBase = convertedIndustryMedians[0];

      const bMax = bMaxBase * multiplier;
      const bMin = bMinBase * multiplier;

      const bounty = projectBounty(bMax, bMin, curve, cvss);
      bounties.push(bounty);
    }
  }
  if (!options.includeExceptional) {
    let exceptionalBounty = bounties.pop();
    bounties[bounties.length - 1] =
      (bounties[bounties.length - 1] + exceptionalBounty) / 2; // get avarage
  }
  return bounties;
};

// Function to calculate the bounty using projectBounty formula
const projectBounty = (bMax, bMin, n, cvss) => {
  const bDiff = bMax - bMin;
  const bounty = (bDiff / Math.pow(10, n)) * Math.pow(cvss, n) + bMin;
  return Math.ceil(bounty / 25) * 25;
};

function calculateStats(
  tiers,
  convertedIndustryAverages,
  convertedIndustryMedians
) {
  let maxBountyValues = [];
  tiers.forEach((tier) => {
    tier.bounties.forEach((bounty, index) => {
      if (
        isNaN(maxBountyValues[index]) ||
        maxBountyValues[index] === undefined
      ) {
        maxBountyValues[index] = 0;
      }
      maxBountyValues[index] = Math.max(maxBountyValues[index], bounty);
    });
  });
  let bountySum = maxBountyValues.reduce((partialSum, a) => partialSum + a, 0);
  let industryAvgSum = convertedIndustryAverages.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  let industryMedianSum = convertedIndustryMedians.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  let industryAvgDiffPct =
    -Math.round(((industryMedianSum - bountySum) / industryMedianSum) * 1000) /
    10;
  var industryMedianDiffPct =
    -Math.round(((industryAvgSum - bountySum) / industryAvgSum) * 1000) / 10;

  return { industryAvgDiffPct, industryMedianDiffPct, maxBountyValues };
}

export { calculateBountiesForTier, calculateStats };
